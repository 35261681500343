import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { scroller, Events } from "react-scroll";
import { Mode } from "../enums/Mode";
import { Chapter } from "../models/Chapter";
import { VerseModel } from "../models/VerseModel";
import { VerseLocationForText } from "../models/VerseLocationForText";
import "../css/global-styles.css";
import { ToHindi } from "../common/ToHindi";

const appbarHeight = 64;
const verticalOffset = 6;
const defaultFontSizeB = 1.1; // in rem

const useStyles = makeStyles(() => ({
  chapterTitles: {
    color: "#000",
    paddingTop: "20px",
    paddingBottom: "35px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  chaptersTitleReverse: {
    flexDirection: "row-reverse"
  },
  chapterTitleA: {
    fontFamily: "Uthmani",
    fontSize: "25px",
    fontWeight: "normal",
    ["@media (min-width:360px)"]: {
      fontSize: "27px"
    },
    ["@media (min-width:400px)"]: {
      fontSize: "29px"
    },
    margin: "0px",
    padding: "0px"
  },
  chapterTitleB: {
    fontFamily: 'Georgia, "Times New Roman", serif',
    fontSize: "19px",
    fontWeight: "normal",
    ["@media (min-width:360px)"]: {
      fontSize: "21px"
    },
    ["@media (min-width:400px)"]: {
      fontSize: "23px"
    },
    margin: "0px",
    padding: "0px",
    paddingTop: "5px"
  },
  verseBlock: {
    color: "#000000",
    display: "flex"
  },
  verseBlockRows: {
    flexDirection: "column"
  },
  verseBlockColumns: {
    flexDirection: "row"
  },
  verseA: {
    lineHeight: "1.6",
    padding: "8px 0px 5px 0px",
    flexGrow: 1,
    flexBasis: 0
  },
  verseB: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', // doing this because MUI Typography added another div
    lineHeight: "1.5",
    padding: "5px 0px 8px 0px",
    flexGrow: 1,
    flexBasis: 0
  },
  verseBlockSelected: {
    backgroundColor: "#f6fef6"
  },
  verseBlockNotSelected: {
    backgroundColor: "#ffffff"
  },
  borderTopDeep: {
    borderTop: "1px solid #BDFCC9"
  },
  borderBottomDeep: {
    borderBottom: "1px solid #BDFCC9"
  },
  borderTopBlank: {
    borderTop: "1px solid #ffffff"
  },
  borderBottomLight: {
    borderBottom: "1px solid #eaeaea"
  },
  borderBottomBlank: {
    borderBottom: "1px solid #ffffff"
  },
  verseNumberB: {
    color: "#212121",
    fontSize: "85%"
  },
  verseNumberA: {
    color: "#111",
    fontSize: "75%",
    fontFamily: '"Traditional Arabic", "Times New Roman", serif'
  },
  verticalLine: {
    borderLeft: "1px solid #eaeaea",
    margin: "0 10px 0px 10px"
  }
}));

interface QuranTextProps {
  chapter: Chapter;
  mode: Mode;
  verseLocation: VerseLocationForText;
  fontScaling: number;
  arabicFontClassName: string;
  arabicFontScaling: number;
  chapterRenderingDone: Function;
  verseScrollingDone: Function;
  verseClicked: Function;
}

function QuranText(props: QuranTextProps) {
  const {
    chapter,
    mode: mode,
    verseLocation,
    fontScaling,
    arabicFontClassName,
    arabicFontScaling,
    chapterRenderingDone,
    verseScrollingDone,
    verseClicked
  } = props;
  const classes = useStyles(props);

  function handleVerseClicked(event: React.MouseEvent<HTMLElement>) {
    verseClicked(Number(event.currentTarget.id.replace("verse", "")));
  }

  React.useEffect(() => {
    Events.scrollEvent.register("end", function(to) {
      // perform check, because scroll also happens in navigation drawer
      if (to.startsWith("verse")) {
        verseScrollingDone(+to.replace("verse", ""));
      }
    });
  }, []);

  function isElementInViewport(element: HTMLElement, yOffset: number) {
    try {
      var rect = element.getBoundingClientRect();
      return (
        rect.top - yOffset >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /*or $(window).height() */
      );
    } catch (error) {
      return true;
    }
  }

  function isElementScrolledUp(element: HTMLElement, yOffset: number) {
    try {
      var rect = element.getBoundingClientRect();
      return rect.top - yOffset >= 0;
    } catch (error) {
      return true;
    }
  }

  React.useEffect(() => {
    var itemVerse = document.getElementById("verse" + verseLocation.verseNumber);
    var isVerseInViewPort = isElementInViewport(itemVerse, +appbarHeight - verticalOffset);
    var itemTitles = document.getElementById("chapter-titles");
    var isTitlesScrolledUp = isElementScrolledUp(itemTitles, +appbarHeight - verticalOffset);

    if (verseLocation.isScrollToVerse) {
      if (!document.hidden) {
        // scrolling done only if document is visible
        if (verseLocation.verseNumber === 0 || verseLocation.verseNumber === 1) {
          if (!isTitlesScrolledUp) {
            // if already on top, don't scroll
            let option = {
              smooth: verseLocation.isAnimateScroll ? true : false,
              offset: -appbarHeight - 300 // force all the way up
            };
            scroller.scrollTo("verse" + verseLocation.verseNumber, option);
          } else {
            verseScrollingDone(verseLocation.verseNumber);
          }
        } else if (!isVerseInViewPort) {
          // if already in viewport, don't scroll
          let option = {
            smooth: "verseLocation.isAnimateScroll ? true : false",
            offset: -appbarHeight - verticalOffset
          };
          scroller.scrollTo("verse" + verseLocation.verseNumber, option);
        } else {
          verseScrollingDone(verseLocation.verseNumber);
        }
      } else {
        verseScrollingDone(verseLocation.verseNumber);
      }
    } else {
      // do not scroll, since document is hidden
      verseScrollingDone(verseLocation.verseNumber);
    }
  }, [verseLocation.verseNumber, verseLocation.isForceNewVerseNumber]);

  React.useEffect(() => {
    chapterRenderingDone();
  }, [chapter.chapterNumber]);

  let arabicBlockStyle = {
    fontSize: ((fontScaling * arabicFontScaling) / 100).toString() + "rem"
  };

  let englishBlockStyle = {
    fontSize: ((fontScaling * defaultFontSizeB) / 100).toString() + "rem"
  };

  return (
    <div>
      {/* chapter titles */}
      <Typography
        className={
          classes.chapterTitles +
          " " +
          (mode === Mode.ArabicEnglishColumns || mode === Mode.ArabicOnly ? classes.chaptersTitleReverse : null)
        }
        id="chapter-titles"
        variant="h2"
      >
        {mode !== Mode.ArabicOnly && <h2 className={classes.chapterTitleB}>{chapter.nameEnglish}</h2>}
        {mode !== Mode.EnglishOnly && (
          <h2 className={classes.chapterTitleA} dir="rtl" lang="ar">
            سورة {chapter.nameArabic}
          </h2>
        )}
      </Typography>
      {/* verses */}
      {chapter.verses.map((verse: VerseModel) => (
        <div
          id={"verse" + verse.verseNumber.toString()}
          key={"verse" + verse.verseNumber.toString()}
          onClick={handleVerseClicked}
          className={
            classes.verseBlock +
            " " +
            (mode === Mode.ArabicEnglishColumns ? classes.verseBlockColumns : classes.verseBlockRows) +
            " " +
            (verseLocation.verseNumber === verse.verseNumber ? classes.verseBlockSelected : null) +
            " " +
            (verseLocation.verseNumber !== verse.verseNumber ? classes.verseBlockNotSelected : null) +
            " " +
            (verseLocation.verseNumber === verse.verseNumber ? classes.borderTopDeep + " " + classes.borderBottomDeep : null) +
            " " +
            (verseLocation.verseNumber !== verse.verseNumber ? classes.borderTopBlank + " " + classes.borderBottomLight : null) +
            " " +
            (verseLocation.verseNumber === verse.verseNumber + 1 ? classes.borderBottomBlank : null) +
            " " +
            (mode !== Mode.ArabicEnglishRows && verseLocation.verseNumber !== verse.verseNumber ? classes.borderBottomBlank : null)
          }
        >
          {mode !== Mode.EnglishOnly && (
            <div className={classes.verseA} dir="rtl" lang="ar" style={arabicBlockStyle}>
              {verse.verseNumber !== 0 && <span className={classes.verseNumberA}>﴿{ToHindi(verse.verseNumber, "ar")}﴾&nbsp;</span>}
              <span className={arabicFontClassName}>{verse.verseString}</span>
            </div>
          )}
          {mode === Mode.ArabicEnglishColumns && <div className={classes.verticalLine} />}
          {mode !== Mode.ArabicOnly && (
            <div className={classes.verseB} dir="ltr" lang="en" style={englishBlockStyle}>
              {verse.verseNumber !== 0 && <span className={classes.verseNumberB}>{verse.verseNumber}.&nbsp;</span>}
              {verse.verseStringB}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default QuranText;
