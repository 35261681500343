import React from "react";
import { Mode } from "../enums/Mode";
import { AudioSegment } from "../enums/AudioSegment";
import { Chapter } from "../models/Chapter";
import { Recitation } from "../models/Recitation";
import { EnglishRecitation } from "../models/EnglishRecitation";
import ReactAudioPlayer from "react-audio-player";

interface AudioProps {
  chapter: Chapter;
  mode: Mode;
  verseNumber: number;
  recitation: Recitation;
  englishRecitation: EnglishRecitation;
  isAudioPlaying: boolean;
  isAudioPlaying2: boolean;
  audioEnded: Function;
  verseRepeat: number;
}

function AudioComponent(props: AudioProps) {
  const {
    chapter,
    mode: mode,
    verseNumber,
    verseRepeat,
    recitation,
    englishRecitation,
    isAudioPlaying,
    isAudioPlaying2,
    audioEnded
  } = props;

  const defaultAudioSegment = mode === Mode.EnglishOnly ? AudioSegment.B : AudioSegment.A;

  const [verseRepeatCounter, setVerseRepeatCounter] = React.useState(1);
  const [forceRepeatVerseToggle, setForceRepeatVerseToggle] = React.useState(true);
  const [audioSegment, setAudioSegment] = React.useState(defaultAudioSegment);

  const playerA = React.useRef(null);
  const playerB = React.useRef(null);

  function getFileNumberWithLeadingZeros(num: number) {
    return ("00" + num).slice(-3);
  }

  function getVolume() {
    let volume: number;
    if (mode === Mode.ArabicEnglishColumns || mode === Mode.ArabicEnglishRows) {
      if (audioSegment === AudioSegment.A || audioSegment == null) {
        volume = recitation.scaling;
      } else {
        volume = englishRecitation.scaling;
      }
    } else if (mode === Mode.ArabicOnly) {
      volume = recitation.scaling;
    } else {
      volume = englishRecitation.scaling;
    }
    return volume;
  }

  function getEnglishUrl(vNumber: number) {
    const url = `${englishRecitation.urlAllah}${getFileNumberWithLeadingZeros(chapter.chapterNumber)}-${getFileNumberWithLeadingZeros(
      vNumber
    )}.mp3`;
    return url;
  }

  function getArabicUrl(vNumber: number) {
    let url: string;
    if (vNumber === 0 && recitation.bismiSingle) {
      url = `${recitation.baseUrl}001${recitation.seperator}001.mp3`;
    } else {
      url = `${recitation.baseUrl}${getFileNumberWithLeadingZeros(chapter.chapterNumber)}${
        recitation.seperator
      }${getFileNumberWithLeadingZeros(vNumber)}.mp3`;
    }
    return url;
  }

  function getUrl(vNumber: number, mode: Mode) {
    let url: string;
    if (mode === Mode.ArabicEnglishColumns || mode === Mode.ArabicEnglishRows) {
      if (audioSegment === AudioSegment.A || audioSegment == null) {
        url = getArabicUrl(vNumber);
      } else {
        url = getEnglishUrl(vNumber);
      }
    } else if (mode === Mode.ArabicOnly) {
      url = getArabicUrl(vNumber);
    } else {
      url = getEnglishUrl(vNumber);
    }
    return url;
  }

  // when enough audio data is loaded, load next file (for caching)
  React.useEffect(() => {
    playerA.current.audioEl.oncanplaythrough = function() {
      const currentAudioUrl = playerA.current.audioEl.src;
      let nextVerseNumber = getFileNumberWithLeadingZeros(
        +currentAudioUrl.substring(currentAudioUrl.length - 7, currentAudioUrl.length - 4) + 1
      );
      let nextAudioUrl =
        currentAudioUrl.substring(0, currentAudioUrl.length - 7) +
        nextVerseNumber +
        currentAudioUrl.substring(currentAudioUrl.length, currentAudioUrl.length - 4);
      playerB.current.audioEl.src = nextAudioUrl;
    };
  }, []);

  React.useEffect(() => {
    setVerseRepeatCounter(verseRepeat);
    setAudioSegment(defaultAudioSegment);
  }, [verseRepeat, verseNumber]);

  function handleAudioTrackEnded(event: any) {
    if (mode === Mode.ArabicEnglishColumns || mode === Mode.ArabicEnglishRows) {
      if (verseRepeatCounter === 1 && audioSegment === AudioSegment.B) {
        setVerseRepeatCounter(verseRepeat);
        audioEnded();
      } else if (verseRepeatCounter === 1 && audioSegment === AudioSegment.A) {
        setAudioSegment(AudioSegment.B);
      } else if (verseRepeatCounter !== 1 && audioSegment === AudioSegment.B) {
        setAudioSegment(AudioSegment.A);
        setVerseRepeatCounter(verseRepeatCounter - 1);
      } else if (verseRepeatCounter !== 1 && audioSegment === AudioSegment.A) {
        setAudioSegment(AudioSegment.B);
      }
    } else if (mode === Mode.ArabicOnly || mode === Mode.EnglishOnly) {
      if (verseRepeatCounter === 1) {
        setVerseRepeatCounter(verseRepeat);
        audioEnded();
      } else {
        setVerseRepeatCounter(verseRepeatCounter - 1);
        setForceRepeatVerseToggle(false);
        setForceRepeatVerseToggle(true);
      }
    }
  }

  React.useEffect(() => {
    if (!isAudioPlaying || !isAudioPlaying2) {
      playerA.current.audioEl.pause();
    }
  }, [isAudioPlaying, isAudioPlaying2]);

  React.useEffect(() => {
    setVerseRepeatCounter(verseRepeat);
  }, [verseRepeat]);

  return (
    <div>
      <ReactAudioPlayer
        src={isAudioPlaying && isAudioPlaying2 && forceRepeatVerseToggle ? getUrl(verseNumber, mode) : null}
        autoPlay={isAudioPlaying && isAudioPlaying2}
        onEnded={handleAudioTrackEnded}
        muted={!isAudioPlaying || !isAudioPlaying2} // keep this - somehow, on iPhone 5, things do not work without it
        ref={playerA}
        volume={getVolume()}
      />
      <ReactAudioPlayer autoPlay={false} muted={true} ref={playerB} />
    </div>
  );
}

export default AudioComponent;
