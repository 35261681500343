import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const appBarColor = "#1a405f";

const useStyles = makeStyles(() => ({
  progressBar: {
    zIndex: 2000
  },
  progressBarHidden: {
    zIndex: -2000
  }
}));

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#ffffff",
    height: 3,
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%"
  },
  barColorPrimary: {
    backgroundColor: appBarColor,
    height: 3,
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%"
  }
})(LinearProgress);

interface ProgressBarProps {
  isVisible: boolean;
}

function ProgressBar(props: ProgressBarProps) {
  const classes = useStyles(props);

  return (
    <div>
      <ColorLinearProgress className={props.isVisible === true ? classes.progressBar : classes.progressBarHidden} />
    </div>
  );
}

export default ProgressBar;
