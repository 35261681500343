export class VerseLocationForText {
  public verseNumber: number;
  public isScrollToVerse: boolean;
  public isAnimateScroll: boolean;
  public isForceNewVerseNumber: number;

  public constructor(verseNumber: number, isScrollToVerse: boolean, isAnimateScroll: boolean, isForceNewVerseNumber: number) {
    this.verseNumber = verseNumber;
    this.isScrollToVerse = isScrollToVerse;
    this.isAnimateScroll = isAnimateScroll;
    this.isForceNewVerseNumber = isForceNewVerseNumber;
  }
}
