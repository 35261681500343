import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ArrowBack from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import { SearchResult } from "../models/SearchResult";
import queryString from "query-string"; // convert object into url query string
import { ChapterVerseLocation } from "../models/ChapterVerseLocation";
import { VerseLocationForText } from "../models/VerseLocationForText";
import "../css/global-styles.css";
import { isIOS } from "react-device-detect";

const defaultFontSizeB = 1.08; // in rem
const blueColorUrl = "#1565c0";
const resultsPerRequest = 20;
const appBarColor = "#1a405f";
const azureSearchRootUrl = "https://quransearch.search.windows.net/indexes/quran-v2/docs";
const englishGodTranslationCode = "translationCode eq 'en-itani-v2-g'";
const englishAllahTranslationCode = "translationCode eq 'en-itani-v2-a'";
const quranArabicTranslationCode = "translationCode eq 'ar-allah'";
const englishTextFields = "text_en";
const arabicTextFields = "text_ar";

let searchResultsLocal: SearchResult[];

const queryObject = {
  "api-version": "2019-05-06",
  "api-key": "50140970F6927FB81BFA79662E7F86FA",
  queryType: "full",
  searchMode: "all",
  $count: "true",
  $top: resultsPerRequest,
  highlightPreTag: "<b>",
  highlightPostTag: "</b>",
  $orderby: "verseIndex, weighting desc",
  searchFields: "", // will be added later
  highlight: "", // will be added later
  search: "", // will be added later
  $filter: "", // will be added later
  $skip: 0 // will be aded later
};

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2)
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "400px"
    },
    color: "#ffffff"
  },
  inputBase: {
    width: "86%",
    fontSize: "16px",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', // doing this because using a native element (MUI input text element does not support dir)
    paddingRight: "20px",
    float: "right",
    [theme.breakpoints.up("sm")]: {
      width: "350px"
    },
    height: "2.3rem",
    color: "#ffffff",
    backgroundColor: "#ff000000",
    lineHeight: "2.3rem",
    border: "none"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width")
  },
  appBar: {
    backgroundColor: appBarColor,
    position: "relative"
  },
  dialogContent: {
    padding: "0",
    margin: "0"
  },
  content: {
    maxWidth: "700px",
    paddingTop: "60px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  verse: {
    cursor: "pointer",
    lineHeight: "1.5",
    marginBottom: "0.9rem",
    color: "#333",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"', // doing this because MUI Typography added another div
    boxShadow: "0 1px 4px 0 rgba(0,0,0,0.2)",
    borderRadius: "5px",
    padding: "15px"
  },
  wasNotClicked: {
    color: blueColorUrl
  },
  wasClicked: {
    color: "#609"
  },
  spinnerMoreButtonCombo: {
    margin: "30px 0px 30px 0px",
    height: "50px"
  },
  spinner: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  loadMoreButton: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  numberOfResultsFound: {
    fontSize: "17px",
    paddingBottom: "15px",
    paddingLeft: "5px"
  },
  verseNumberA: {
    fontFamily: '"Traditional Arabic", "Times New Roman", serif',
    fontSize: "1.2rem"
  }
}));

const ColorButton = withStyles(() => ({
  root: {
    color: "#ffffff",
    backgroundColor: blueColorUrl,
    "&:hover": {
      backgroundColor: fade(blueColorUrl, 0.9)
    }
  }
}))(Button);

const ColorProgress = withStyles({
  root: {
    color: blueColorUrl
  }
})(CircularProgress);

// eslint-disable-next-line
const Transition: any = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getSearchResultsCount(searchReply: any) {
  return searchReply["@odata.count"];
}

function isTextArabic(text: string) {
  var arabic = /[\u0600-\u06FF]/;
  return arabic.test(text);
}

interface SearchProps {
  fontScaling: number;
  isOpen: boolean;
  searchVerseClick: Function;
  handleClose: () => void;
  chaptersInfo: any;
  arabicFontClassName?: string;
  arabicFontScaling?: number;
}

function Search(props: SearchProps) {
  const classes = useStyles(props);
  const [searchResults, setSearchResults] = React.useState([]);
  const [isSpinnerVisible, setIsSpinnerVisible] = React.useState(false);
  const [isLoadMoreButtonVisible, setIsLoadMoreButtonVisible] = React.useState(false);
  const [numberOfResultsFound, setNumberOfResultsFound] = React.useState(-1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getSearchResultsObjects(searchReply: any) {
    var searchResults: SearchResult[] = [];
    for (var i = 0; i < searchReply.value.length; i++) {
      var textFieldName = "text_" + searchReply.value[i].languageCode;
      // loop through all snippets, and get highlighted words from the snippets
      var allHighlightedWords: string[] = [];
      for (let snippet of searchReply.value[i]["@search.highlights"]["text_" + searchReply.value[i].languageCode]) {
        var highlightedWords = snippet.match(/<b>(.*?)<\/b>/g).map(function(val: string) {
          return val.replace(/<\/?b>/g, "");
        });
        allHighlightedWords = allHighlightedWords.concat(highlightedWords); // add to array
      }
      allHighlightedWords = allHighlightedWords.filter((item: string, index: number) => allHighlightedWords.indexOf(item) === index); // remove duplicates
      let returnedText = searchReply.value[i][textFieldName];
      // now highlight what needs highlighting
      for (let highlightedWord of allHighlightedWords) {
        returnedText = returnedText.replace(
          new RegExp(highlightedWord, "g"),
          '<span style="font-weight:bold">' + highlightedWord + "</span>"
        );
      }
      searchResults.push(
        new SearchResult(
          searchReply.value[i].chapterNumber,
          searchReply.value[i].verseNumber,
          returnedText,
          false,
          searchReply.value[i].direction,
          searchReply.value[i].languageCode
        )
      );
    }
    return searchResults;
  }

  function searchAzure(searchString: string) {
    setIsSpinnerVisible(true);
    setIsLoadMoreButtonVisible(false);
    queryObject.search = searchString.replace("!", "");

    if (isTextArabic(searchString)) {
      queryObject.$filter = quranArabicTranslationCode;
      queryObject.searchFields = arabicTextFields;
      queryObject.highlight = arabicTextFields;
    } else {
      if (searchString.toLowerCase().includes("allah")) {
        queryObject.$filter = englishAllahTranslationCode;
      } else {
        queryObject.$filter = englishGodTranslationCode;
      }
      queryObject.searchFields = englishTextFields;
      queryObject.highlight = englishTextFields;
    }

    queryObject.$skip = searchResultsLocal.length;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const queryStr = queryString.stringify(queryObject);
    axios
      .get(azureSearchRootUrl + "?" + queryStr)
      .then(res => {
        const searchReply = res.data;
        if (searchReply.value.length > 0) {
          searchResultsLocal = searchResultsLocal.concat(getSearchResultsObjects(searchReply));
          setSearchResults(searchResultsLocal);
        }
        setNumberOfResultsFound(searchReply["@odata.count"]);
        setIsSpinnerVisible(false);
        if (searchResultsLocal.length < getSearchResultsCount(searchReply)) {
          setIsLoadMoreButtonVisible(true);
        }
      })
      .catch(function() {
        setIsSpinnerVisible(false);
        setIsLoadMoreButtonVisible(false);
      });
  }

  function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    setNumberOfResultsFound(-1);
    setSearchResults([]);
    searchResultsLocal = [];
    if (event != null) {
      event.preventDefault();
      // @ts-ignore
      searchAzure(document.getElementById("input-base-search").value.toString());
    }
    document.getElementById("input-base-search").blur(); // to close the virtual keyboard
  }

  function onLoadMore(event: React.MouseEvent<HTMLButtonElement>) {
    if (event != null) {
      event.preventDefault();
      // @ts-ignore
      searchAzure(document.getElementById("input-base-search").value.toString());
    }
  }

  function verseClicked(event: React.MouseEvent<HTMLElement>) {
    // make sure click not triggered by user selecting text
    if (window.getSelection().toString().length === 0) {
      const stringArray = event.currentTarget.id.replace("search-result-", "").split("-");
      const gotoLocation = new ChapterVerseLocation(
        Number(stringArray[0]),
        new VerseLocationForText(Number(stringArray[1]), true, true, 0)
      );
      const index = Number(stringArray[2]);
      let objs = searchResults;
      objs[index].wasClicked = true; // to change color when a verse is clicked
      setSearchResults(objs);
      props.searchVerseClick(gotoLocation);
    }
  }

  function getChapterNameArabic(chapterNumber: number) {
    return props.chaptersInfo.edges[chapterNumber - 1].node.nameArabic;
  }

  React.useEffect(() => {
    if (props.isOpen === true && searchResults.length === 0) {
      var inputElement = document.getElementById("input-base-search"); // put cursor in search box under some conditions
      if (!isIOS) {
        // focus caused problems with iOS
        inputElement.focus();
      }
      inputElement.addEventListener("mouseover", function() {
        // mouseover event listener - to put cursor in search box when mouseover
        inputElement.focus();
      });
    }
  }, [props.isOpen]);

  let arabicBlockStyle = {
    fontSize: ((props.fontScaling * props.arabicFontScaling) / 100).toString() + "rem"
  };

  let englishBlockStyle = {
    fontSize: ((props.fontScaling * defaultFontSizeB) / 100).toString() + "rem"
  };

  return (
    <Dialog keepMounted fullScreen open={props.isOpen} onClose={props.handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" className={classes.backButton} color="inherit" aria-label="Back" onClick={props.handleClose}>
            <ArrowBack />
          </IconButton>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form noValidate autoComplete="off" onSubmit={onFormSubmit} action=".">
              <input placeholder="Search Quran…" id="input-base-search" className={classes.inputBase} type="search" dir="auto" required />
            </form>
          </div>
        </Toolbar>
      </AppBar>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.content}>
          <Typography
            className={classes.numberOfResultsFound}
            style={numberOfResultsFound === 0 ? { display: "block" } : { display: "none" }}
          >
            No results found
          </Typography>
          <Typography
            className={classes.numberOfResultsFound}
            style={numberOfResultsFound === 1 ? { display: "block" } : { display: "none" }}
          >
            1 result found
          </Typography>
          <Typography
            className={classes.numberOfResultsFound}
            style={numberOfResultsFound > 1 ? { display: "block" } : { display: "none" }}
          >
            {numberOfResultsFound} results found
          </Typography>
          {searchResults.map((item, index) => (
            <div
              className={classes.verse}
              key={`search-result-${item.chapterNumber.toString()}-${item.verseNumber.toString()}`}
              id={`search-result-${item.chapterNumber.toString()}-${item.verseNumber.toString()}-${index.toString()}`}
              onClick={verseClicked}
              dir={item.direction}
              lang={item.languageCode}
            >
              {item.languageCode === "en" && (
                <div style={englishBlockStyle}>
                  <span className={item.wasClicked ? classes.wasClicked : classes.wasNotClicked}>
                    [{item.chapterNumber}:{item.verseNumber}]
                  </span>
                  &nbsp;&nbsp;
                  <span contentEditable={false} dangerouslySetInnerHTML={{ __html: item.text }} />
                </div>
              )}
              {item.languageCode === "ar" && (
                <div style={arabicBlockStyle}>
                  <span className={classes.verseNumberA + " " + (item.wasClicked ? classes.wasClicked : classes.wasNotClicked)}>
                    ﴿ {getChapterNameArabic(item.chapterNumber)} {item.verseNumber} ﴾
                  </span>
                  &nbsp;&nbsp;
                  <span className={props.arabicFontClassName} contentEditable={false} dangerouslySetInnerHTML={{ __html: item.text }} />
                </div>
              )}
            </div>
          ))}

          <div className={classes.spinnerMoreButtonCombo}>
            <ColorProgress size={30} className={classes.spinner} style={isSpinnerVisible ? { display: "block" } : { display: "none" }} />
            <ColorButton
              variant="contained"
              className={classes.loadMoreButton}
              style={isLoadMoreButtonVisible ? { display: "block" } : { display: "none" }}
              onClick={onLoadMore}
            >
              More
            </ColorButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default Search;
