import { VerseLocationForText } from "./VerseLocationForText";

export class ChapterVerseLocation {
  public chapterNumber: number;
  public verseLocationForText: VerseLocationForText;

  public constructor(chapterNumber: number, verseLocationForText: VerseLocationForText) {
    this.chapterNumber = chapterNumber;
    this.verseLocationForText = verseLocationForText;
  }
}
