import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { scroller } from "react-scroll";
import "../css/global-styles.css";

const drawerWidth = 235;
const topOffset = 0;

const useStyles = makeStyles(theme => ({
  text: {
    marginLeft: "5px"
  },
  textPrimary: {
    fontSize: "1.4rem",
    fontFamily: "Uthmani",
    color: "#111",
    lang: "ar",
    dir: "rtl"
  },
  textSecondary: {
    fontSize: "1.0rem",
    color: "#666"
  },
  textSelected: {
    fontWeight: "bold"
  },
  avatar: {
    backgroundColor: "#678DAC",
    fontWeight: "normal"
  },
  menuItem: {
    cursor: "pointer"
  },
  menuItemNotSelected: {
    background: "#ffffff"
  },
  avatarSelected: {
    backgroundColor: "#1a405f",
    fontWeight: "bold"
  },
  menuItemSelected: {
    background: "#cccccc"
  },
  //
  drawerTitleText: {
    paddingLeft: "25px",
    position: "absolute",
    top: "31%",
    color: "#444",
    fontSize: "1.1rem",
    fontWeight: "bold"
  },
  root: {
    display: "flex",
    backgroundColor: "#ffffff"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  toolbarTitle: {
    position: "relative"
  },
  drawerContent: {
    position: "relative",
    overflowY: "scroll"
  }
}));

interface DrawerChapterProps {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  newChapterClicked: Function;
  chapterNumber: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chaptersInfo: any;
  isWindowWide: boolean;
}

function DrawerChapter(props: DrawerChapterProps) {
  const classes = useStyles(props);
  const theme = useTheme();

  function handleItemClicked(event: React.MouseEvent<HTMLElement>) {
    let chapterNumber = Number(event.currentTarget.id.replace("drawer-content-id", "")) + 1;
    props.newChapterClicked(chapterNumber);
  }

  function isElementInViewport(element: HTMLElement, yOffset: number) {
    try {
      var rect = element.getBoundingClientRect();
      return rect.top >= 0 && rect.bottom - yOffset <= (window.innerHeight || document.documentElement.clientHeight);
    } catch (error) {
      return true;
    }
  }

  React.useEffect(() => {
    // autoscroll for drawer a
    var item = document.getElementById("drawer-content-id" + (props.chapterNumber - 1));
    var isInViewPort = isElementInViewport(item, topOffset);
    if (!isInViewPort) {
      let option = {
        containerId: "drawer-content-id"
      };
      scroller.scrollTo("drawer-content-id" + (props.chapterNumber - 1), option);
    }
  }, [props.chapterNumber, props.isOpen, props.isWindowWide]); // when chapterNumber changes

  return (
    <nav className={classes.drawer}>
      <Drawer
        variant={props.isWindowWide ? "permanent" : "temporary"}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={props.isOpen}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={props.handleCloseDrawer}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <nav className={classes.drawerContent} id="drawer-content-id">
          <div className={classes.toolbar + " " + classes.toolbarTitle}>
            <Typography className={classes.drawerTitleText}>Chapters &#x2022; Suras</Typography>
          </div>
          <Divider />
          {/* List of chapters */}
          <List dense={true}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {props.chaptersInfo.edges.map((item: any, index: number) => (
              <ListItem
                key={"drawer-content-id" + index.toString()}
                id={"drawer-content-id" + index.toString()}
                onClick={handleItemClicked}
                className={
                  classes.menuItem + " " + (props.chapterNumber === index + 1 ? classes.menuItemSelected : classes.menuItemNotSelected)
                }
              >
                <Avatar className={props.chapterNumber === index + 1 ? classes.avatarSelected : classes.avatar}>{index + 1}</Avatar>
                <ListItemText
                  classes={
                    props.chapterNumber === index + 1
                      ? {
                          primary: classes.text + " " + classes.textSelected + " " + classes.textPrimary,
                          secondary: classes.text + " " + classes.textSelected + " " + classes.textSecondary
                        }
                      : {
                          primary: classes.text + " " + classes.textPrimary,
                          secondary: classes.text + " " + classes.textSecondary
                        }
                  }
                  primary={item.node.nameArabic}
                  secondary={item.node.nameEnglish}
                />
              </ListItem>
            ))}
          </List>
        </nav>
      </Drawer>
    </nav>
  );
}

export default DrawerChapter;
