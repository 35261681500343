import React from "react";
import { Helmet } from "react-helmet";
import { Chapter } from "../models/Chapter";

interface MetaTagsProps {
  chapter: Chapter;
}

function MetaTags(props: MetaTagsProps) {
  const chapter = props.chapter;
  const chapterNumber = props.chapter.chapterNumber;

  const baseUrl = "https://www.corequran.com";
  const imageUrl = "https://www.corequran.com/images/quran-in-english-and-arabic-logo.png";

  const titleChapter1 = `Quran in Arabic and English: Read and Listen with Ease`;
  const descriptionChapter1 = `Explore the Quran in Arabic and English with crisp text, clear translation, and beautiful recitations. Perfect for reading, listening, studying on any device.`;

  let title;
  let description;
  let pageUrl;
  if (chapter.chapterNumber === 1) {
    title = titleChapter1;
    description = descriptionChapter1;
    pageUrl = baseUrl + "/";
  } else {
    title = `Surah ${chapter.nameTrans.replace("-", " ")}: Arabic & English: Read, Listen, Search`;
    description = `Explore Surah ${chapter.nameTrans} in Arabic and English: Read and listen with ease. Crisp text, clear translation, beautiful recitations. Perfect for any device.`;
    pageUrl = `${baseUrl}/${chapterNumber}/`;
  }

  return (
    <Helmet
      // structured data
      script={[
        {
          type: "application/ld+json",
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Website",
            "url": "${pageUrl}",
            "name": "CoreQuran",
            "description": "${description}",      
            "author": "Talal Itani",
            "logo": "${imageUrl}",
            "image": "${imageUrl}"
          }`
        }
      ]}
    >
      <html lang="en" />
      <link rel="canonical" href={pageUrl} />

      {/* meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="author" content="Talal Itani" />
      <meta name="robots" content="index, follow" />

      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content="book" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:site_name" content="Quran Arabic English" />
      <meta property="fb:admins" content="683971979" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ClearQuran" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="@ClearQuran" />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
}

export default MetaTags;
