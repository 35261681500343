import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";
import SettingsIcon from "@material-ui/icons/Settings";
import SwapVert from "@material-ui/icons/SwapVert";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import ButtonBase from "@material-ui/core/ButtonBase";
import MenuPages from "./MenuPages";
import { navigate } from "gatsby";

const drawerWidth = 235;
const appBarColor = "#1a405f";

let anchorPagesMenu: HTMLElement;

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "1.5rem",
    ["@media (min-width:375px)"]: {
      fontSize: "1.8rem"
    }
  },
  iconScheme1: {
    color: "#e0e0e0"
  },
  iconScheme2: {
    color: "#ffffff"
  },
  iconButtonRight: {
    padding: "4px",
    margin: "0px",
    ["@media (min-width:340px)"]: {
      marginLeft: "0px"
    },
    ["@media (min-width:412px)"]: {
      marginLeft: "6px"
    },
    ["@media (min-width:500px)"]: {
      marginLeft: "20px"
    },
    ["@media (min-width:780px)"]: {
      marginLeft: "35px"
    }
  },
  iconsWrapper: {
    marginLeft: "auto",
    padding: "0px",
    marginRight: "0px",
    ["@media (min-width:780px)"]: {
      marginRight: "25px"
    }
  },
  toolbarClass2: {
    position: "relative"
  },
  appbarTitle: {
    fontSize: "1.5rem",
    ["@media (min-width:350px)"]: {
      letterSpacing: "0.15rem",
      fontSize: "1.8rem"
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    backgroundColor: appBarColor,
    display: "flex"
  },
  progressBar: {
    zIndex: 2000
  },
  chapterNumber: {
    fontSize: "20px",
    fontWeight: 300,
    color: "#ffffff",
    padding: "0px",
    letterSpacing: "-1px",
    width: "35px",
    lineHeight: "35px",
    Height: "35px",
    textAlign: "center",
    border: "1px solid #ffffff",
    borderRadius: "5px",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  chapterNumberTight: {
    letterSpacing: "-2px"
  },
  chapterNumberButton: {
    marginRight: "10px"
  }
}));

interface AppBarChapterProps {
  isAppInstalled: boolean;
  isAudioPlaying: boolean;
  chapterNumber: number;
  onClickMenuButton: () => void;
  onClickTitle: () => void;
  onClickPlayPauseButton: () => void;
  onClickSearchButton: () => void;
  onClickVerticalSwapButton: (event: React.MouseEvent<HTMLElement>) => void;
  onClickSettingsButton: () => void;
  bookmarkClicked?: () => void;
  gotoBookmarkClicked?: () => void;
  shareVersesClicked?: () => void;
}

function AppBarChapter(props: AppBarChapterProps) {
  const classes = useStyles(props);

  const [isPagesMenuOpen, setIsPagesMenuOpen] = React.useState(false);
  const [isSharingAvailable, setIsSharingAvailable] = React.useState(false);

  React.useEffect(() => {
    // @ts-ignore
    if (navigator.share) {
      setIsSharingAvailable(true);
    }
  }, []);

  function handlePagesMenuClick(event: React.MouseEvent<HTMLElement>) {
    anchorPagesMenu = event.currentTarget;
    setIsPagesMenuOpen(true);
  }

  function handleBookmarkClicked() {
    setIsPagesMenuOpen(false);
    props.bookmarkClicked();
  }

  function handleGotoBookmarkClicked() {
    setIsPagesMenuOpen(false);
    props.gotoBookmarkClicked();
  }

  function handleShareVersesClicked() {
    setIsPagesMenuOpen(false);
    props.shareVersesClicked();
  }

  function gotoNewUrl(url: string) {
    setIsPagesMenuOpen(false);
    navigate(url);
  }

  return (
    <div id="app-bar">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <a id="chapters-menu-button">
            <ButtonBase onClick={props.onClickMenuButton} className={classes.chapterNumberButton} aria-label="Open Drawer">
              <Typography className={classes.chapterNumber + " " + (props.chapterNumber > 99 ? classes.chapterNumberTight : null)}>
                {props.chapterNumber}
              </Typography>
            </ButtonBase>
          </a>

          <ButtonBase onClick={props.onClickTitle}>
            <Typography className={classes.appbarTitle} noWrap variant="h1">
              Quran
            </Typography>
          </ButtonBase>

          <div className={classes.iconsWrapper}>
            {/* Play/Pause Button */}
            <a id="play-pause-button">
              <IconButton
                size="medium"
                color="inherit"
                aria-label="Play Pause"
                edge="start"
                onClick={props.onClickPlayPauseButton}
                className={classes.iconButtonRight}
              >
                {props.isAudioPlaying === false && <PlayArrow className={classes.icon + " " + classes.iconScheme2} />}
                {props.isAudioPlaying === true && <Pause className={classes.icon + " " + classes.iconScheme2} />}
              </IconButton>
            </a>

            {/* Search Button */}
            <a id="search-button">
              <IconButton
                size="medium"
                color="inherit"
                aria-label="Search"
                edge="start"
                onClick={props.onClickSearchButton}
                className={classes.iconButtonRight}
              >
                <SearchIcon className={classes.icon + " " + classes.iconScheme2} />
              </IconButton>
            </a>

            {/* Go To Verse Button */}
            <a id="go-to-verse-button">
              <IconButton
                size="medium"
                color="inherit"
                aria-label="Go to Verse"
                edge="start"
                onClick={props.onClickVerticalSwapButton}
                className={classes.iconButtonRight}
              >
                <SwapVert className={classes.icon + " " + classes.iconScheme1} />
              </IconButton>
            </a>

            {/* Settings Button */}
            <a id="settings-button">
              <IconButton
                size="medium"
                color="inherit"
                aria-label="Settings"
                edge="start"
                onClick={props.onClickSettingsButton}
                className={classes.iconButtonRight}
              >
                <SettingsIcon className={classes.icon + " " + classes.iconScheme1} />
              </IconButton>
            </a>

            {/* Pages Menu Button */}
            <a id="pages-menu-button">
              <IconButton
                size="medium"
                color="inherit"
                aria-label="Menu"
                edge="start"
                onClick={handlePagesMenuClick}
                className={classes.iconButtonRight}
              >
                <MenuIcon className={classes.icon + " " + classes.iconScheme1} />
              </IconButton>
            </a>
          </div>
        </Toolbar>
      </AppBar>
      <MenuPages
        isOpen={isPagesMenuOpen}
        onClose={() => setIsPagesMenuOpen(false)}
        chapterNumber={props.chapterNumber}
        isSharingAvailable={isSharingAvailable}
        anchorEl={anchorPagesMenu}
        isShowBookmarkEntries={true}
        bookmarkClicked={handleBookmarkClicked}
        gotoBookmarkClicked={handleGotoBookmarkClicked}
        shareVersesClicked={handleShareVersesClicked}
        onNewUrl={gotoNewUrl}
        isAppInstalled={props.isAppInstalled}
      />
    </div>
  );
}

export default AppBarChapter;
