import React from "react";
import axios from "axios";

function getFileNumberWithLeadingZeros(num: number) {
  return ("00" + num).slice(-3);
}

interface GetChapterProps {
  chapterNumber: number;
  isForCaching: boolean;
  newChapterLoaded: Function;
  newChapterCached: Function;
}

function GetChapter(props: GetChapterProps): null {
  const { chapterNumber, isForCaching, newChapterLoaded, newChapterCached } = props;

  React.useEffect(() => {
    const chNumber = chapterNumber <= 114 ? chapterNumber : 1;
    axios
      .get(`/translation/quran-${getFileNumberWithLeadingZeros(chNumber)}.json`)
      .then(res => {
        if (!isForCaching) {
          newChapterLoaded(res.data);
        } else {
          newChapterCached();
        }
      })
      .catch(function() {});
  });

  return null;
}

export default GetChapter;
