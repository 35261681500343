import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Chapter } from "../models/Chapter";
import { VerseModel } from "../models/VerseModel";
import { websiteUrl } from "../constants/BaseConstants";
import { ToHindi } from "../common/ToHindi";
import { Mode } from "../enums/Mode";

const blueColor = "#1f4c9f";
const baseUrl = websiteUrl;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueColor,
      dark: blueColor
    }
  }
});

const useStyles = makeStyles(() => ({
  dialog: {},
  appBar: {
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  content: {
    marginTop: "20px",
    marginBottom: "30px",
    marginLeft: "30px",
    marginRight: "30px",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  formControl: {
    width: "100px",
    margin: "0px",
    marginBottom: "30px"
  },
  select: {},
  shareButton: {
    width: "100px",
    marginTop: "5px"
  }
}));

interface ShareVersesDialogProps {
  currentVerseNumber: number;
  chapter: Chapter;
  isOpen: boolean;
  mode: Mode;
  handleCloseDialog: () => void;
}

function ShareVersesDialog(props: ShareVersesDialogProps) {
  const [fromVerseNumber, setFromVerseNumber] = React.useState(props.currentVerseNumber);
  const [toVerseNumber, setToVerseNumber] = React.useState(props.currentVerseNumber);
  const classes = useStyles(props);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleFromVerseChange(event: any) {
    setFromVerseNumber(event.target.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleToVerseChange(event: any) {
    setToVerseNumber(event.target.value);
  }

  function onClickShareButton() {
    let min = Math.min(fromVerseNumber, toVerseNumber);
    let max = Math.max(fromVerseNumber, toVerseNumber);
    let text = "";

    if (props.mode === Mode.ArabicEnglishColumns || props.mode === Mode.ArabicEnglishRows || props.mode === Mode.ArabicOnly) {
      const minString = min === 0 ? "" : ToHindi(min, "ar");
      let currentVerse = props.chapter.verses.filter(verse => verse.verseNumber == min)[0].verseString;
      text = `﴿${ToHindi(props.chapter.chapterNumber, "ar")} ${props.chapter.nameArabic} ${minString}﴾ ${currentVerse}`;
      for (let i = min + 1; i <= max; i++) {
        currentVerse = props.chapter.verses.filter(verse => verse.verseNumber == i)[0].verseString;
        text = `${text}\r\n\r\n﴿${ToHindi(i, "ar")}﴾ ${currentVerse}`;
      }
    }
    if (props.mode === Mode.ArabicEnglishColumns || props.mode === Mode.ArabicEnglishRows) {
      text = `${text}\r\n\r\n`;
    }
    if (props.mode === Mode.ArabicEnglishColumns || props.mode === Mode.ArabicEnglishRows || props.mode === Mode.EnglishOnly) {
      let currentVerse = props.chapter.verses.filter(verse => verse.verseNumber == min)[0].verseStringB;
      text = `${text}[Quran ${props.chapter.chapterNumber}:${min}] ${currentVerse}`;
      for (let i = min + 1; i <= max; i++) {
        currentVerse = props.chapter.verses.filter(verse => verse.verseNumber == i)[0].verseStringB;
        text = `${text}\r\n\r\n${i.toString()}. ${currentVerse}`;
      }
    }
    text = `${text}\r\n\r\n`;
    let url = `${baseUrl}${props.chapter.chapterNumber.toString()}#${min.toString()}`;

    // @ts-ignore
    navigator.share({
      title: "Quran",
      text: text,
      url: url
    });
    props.handleCloseDialog();
  }

  React.useEffect(() => {
    setFromVerseNumber(props.currentVerseNumber);
    setToVerseNumber(props.currentVerseNumber);
  }, [props.currentVerseNumber]);

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog onClose={props.handleCloseDialog} aria-labelledby="customized-dialog-title" open={props.isOpen} className={classes.dialog}>
        <DialogTitle>
          <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleCloseDialog}>
            <CloseIcon />
          </IconButton>
          Share Verses
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.content}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="from-verse">From Verse</InputLabel>
              <Select
                value={fromVerseNumber}
                onChange={event => handleFromVerseChange(event)}
                inputProps={{
                  name: "from-selector",
                  id: "from-selector"
                }}
                className={classes.select}
              >
                {props.chapter.verses.map((item: VerseModel) => (
                  <MenuItem value={item.verseNumber} key={"from" + item.verseNumber.toString()} id={"from" + item.verseNumber.toString()}>
                    {item.verseNumber}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="from-verse">To Verse</InputLabel>
              <Select
                value={toVerseNumber}
                onChange={event => handleToVerseChange(event)}
                inputProps={{
                  name: "to-selector",
                  id: "to-selector"
                }}
                className={classes.select}
              >
                {props.chapter.verses.map((item: VerseModel) => (
                  <MenuItem value={item.verseNumber} key={"to" + item.verseNumber.toString()} id={"to" + item.verseNumber.toString()}>
                    {item.verseNumber}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <a id="share-verses-button">
              <Button variant="contained" color="primary" className={classes.shareButton} type="submit" onClick={onClickShareButton}>
                &nbsp;Share&nbsp;
              </Button>
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default ShareVersesDialog;
